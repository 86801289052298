import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { Modal, ModalHeader } from "reactstrap";
import { getSaloonById } from "../../redux/saloon/SaloonSlice";
import Table from 'react-bootstrap/Table';


const ServiceList = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const { saloonList } = useSelector((store) => store.saloon);

    const [selectBrand, setSelectBrand] = useState("");
    const [cancelmodel, setCancelModel] = useState(false);
    const [saloonDetail, setSaloonDetail] = useState('')
    useEffect(() => {
        const getSaloonDetails = async () => {
            const getData = await dispatch(getSaloonById(params._id))
            if (getData.payload.success) {
                console.log(getData.payload.saloon, 'getData.payload.data')
                setSaloonDetail(getData.payload.saloon)
            }
        }
        getSaloonDetails()
    }, [params._id])

    const cancelClick = (e, subcategory) => {
        setCancelModel(true);
        setSelectBrand(subcategory);
    };

    const deleteClick = async (e, id) => {
        // const data = await dispatch(brandDelete(selectBrand._id));
    };
    const statusChange = async (e, data) => {
        e.preventDefault();
        console.log(data);
        const value = e.target.checked;

        if (data) {
            const formData = {
                brandid: data._id,
                status: value,
            };
            console.log(formData);
            //   const updateCat = await dispatch(brandUpdate(formData));
        }
    };
    const columns = [
        {
            field: "id",
            headerName: "S. No",
            filterable: false,
            renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: "SERVICE NAME",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "show",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <>
                        <div className="d-flex m-auto">
                            <Col>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label=" "
                                    value={params.row.status}
                                    checked={params.row.status}
                                    onChange={(e) => statusChange(e, params.row)}
                                />
                            </Col>
                        </div>
                    </>
                );
            },
        },

        {
            field: "ACTION",
            width: 240,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto">
                        <Link to={`/admin/updatesaloon/${params.row.slugUrl}`}>
                            <Button variant="contained" color="primary">
                                EDIT
                            </Button>
                        </Link>
                    </div>
                );
            },
        },
        {
            field: "ACTION.",
            width: 240,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <div className="d-flex m-auto">
                        <Button
                            variant="contained"
                            onClick={(e) => cancelClick(e, cellValues.row)}
                            color="error"
                        >
                            Delete
                        </Button>
                    </div>
                );
            },
        },
    ];
    // console.log(saloonDetail.servicesList,'saloonDetail.servicesList');
    // console.log(saloonDetail,'saloonDetail');
    return (
        <>
            <Fragment>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start'}}>
                    <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: '600', width: '75%' }}>
                        Service provided by {saloonDetail.name}
                    </div>
                    <div style={{width:'25%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <Link to={`/admin/addservices/${saloonDetail._id}`}>
                            <button style={{ backgroundColor: '#ffcc00', border: '1px solid #ffcc00', color: '#fff', margin: '5px', borderRadius: '5px' }} >Add Service</button>
                        </Link>
                    </div>
                </div>
                <div className="content-wrapper ">
                    <div
                        style={{ height: 650, width: "100%" }}
                        className="d-flex m-auto text-align-center"
                    >
                        <Table hover class="table table-striped">
                            <thead>
                                <tr >
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        First Name
                                    </th>
                                    <th>
                                        Last Name
                                    </th>
                                    <th>
                                        Username
                                    </th>
                                    <th>
                                        Show
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    saloonDetail && saloonDetail.servicesList.map((indService, index) =>
                                        <tr style={{ height: '50px', width: '240px' }}>
                                            <th scope="row" style={{ height: '50px' }}>
                                                {index + 1}
                                            </th>
                                            <td style={{ height: '50px' }}>
                                                {indService.name}
                                            </td>
                                            <td style={{ height: '50px' }}>
                                                {indService.mrp}
                                            </td>
                                            <td style={{ height: '50px' }}>
                                                {indService.price}
                                            </td>
                                            <td style={{ height: '50px' }}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label=" "
                                                    value={indService?.show}
                                                    checked={indService?.show}
                                                    // onChange={(e) => statusChange(e, params.row)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>

                    <Modal
                        size="md"
                        isOpen={cancelmodel}
                        toggle={() => setCancelModel(!cancelmodel)}
                    >
                        <ModalHeader>
                            <div className=" ">
                                <div className="col-12 ">
                                    <div className="col-12">
                                        <h3>
                                            Do You Want to{" "}
                                            <span style={{ color: "red" }}>Delete</span>{" "}
                                            {selectBrand !== "" ? (
                                                <>
                                                    <span
                                                        style={{
                                                            color: "#dc3545",
                                                        }}
                                                    >
                                                        {selectBrand.name}
                                                    </span>
                                                </>
                                            ) : (
                                                <></>
                                            )}{" "}
                                            Brand
                                        </h3>
                                        <div className="col-12 mt-3 ">
                                            <div className="col-6 d-flex justify-content-between">
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    className="mx-1 px-5"
                                                    onClick={() => setCancelModel(false)}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="mx-1 px-5"
                                                    onClick={(e) => deleteClick(e)}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                    </Modal>
                </div>
            </Fragment>
        </>
    );
};

export default ServiceList;
