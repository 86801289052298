import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [date, setDate] = useState(0);
  const getYear = () => setDate(new Date().getFullYear());
  useEffect(() => {
    getYear();
  }, []);

  return (
    <div>
      <footer className="main-footer" style={{ position: "fixed", bottom: 0,width:'100%' }}>
        <strong>
          Copyright © {date}{" "}
          <Link to="/admin" className="px-2">
            Saloon Treat
          </Link>
        </strong>
        All rights reserved.
      </footer>
    </div>
  );
};
export default Footer;
