import "./App.css";
import { Route, Routes, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Error from "../src/pages/Error";
import Loginpage from "./pages/Loginpage";
import AddSuperCategory from "./pages/add-edit/superCategory/AddSuperCategory";
import ListSuperCategory from "./pages/add-edit/superCategory/ListSuperCategory";
import AddCategory from "./pages/add-edit/category/AddCategory";
import ListCategory from "./pages/add-edit/category/ListCategory";
import AddBrand from "./pages/add-edit/brand/AddBrand";
import ListBrand from "./pages/add-edit/brand/ListBrand";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import { getCategory } from "./redux/category/categorySlice";
import { getBrand } from "./redux/brand/brandSlice";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import { getProduct } from "./redux/product/productSlice";
import AddSubCategory from "./pages/add-edit/subcategory/AddSubCategory";
import ListSubCategory from "./pages/add-edit/subcategory/ListSubCategory";
import UpdateCategory from "./pages/add-edit/category/UpdateCategory";
import UpdateSubCategory from "./pages/add-edit/subcategory/UpdateSubCategory";
import UpdateBrand from "./pages/add-edit/brand/UpdateBrand";
import UpdateSuperCategory from "./pages/add-edit/superCategory/UpdateSuperCategory";
import AddGrocery from "./pages/add-edit/grocery/AddGrocery";
import ListGrocery from "./pages/add-edit/grocery/ListGrocery";
import UpdateGrocery from "./pages/add-edit/grocery/UpdateGrocery";
import BookedOrder from "./pages/orders/BookedOrder";
import CancelledOrder from "./pages/orders/CancelledOrder";
import DeliveredOrder from "./pages/orders/DeliveredOrder";
import DispatchOrder from "./pages/orders/DispatchOrder";
import OutForDeliveryOrder from "./pages/orders/OutForDeliveryOrder";
import ProcessingOrder from "./pages/orders/ProcessingOrder";
import OrderDetails from "./pages/orders/OrderDetails";
import AssignDeliveryPersion from "./pages/orders/AssignDeliveryPersion";
import OrderInvoice from "./pages/orders/OrderInvoice";
import AddServices from "./pages/add-edit/services/AddServices";
import { getservices } from "./redux/services/ServicesSlice";
import AddCar from "./pages/add-edit/car/AddCar";
import { getCar } from "./redux/car/CarSlice";
import CarModelAdd from "./pages/add-edit/car/CarModelAdd";
import AddSaloon from "./pages/add-saloon/AddSaloon";
import ListSaloon from "./pages/add-saloon/ListSaloon";
import UpdateSaloon from "./pages/add-saloon/UpdateSaloon";
import { getSaloonList } from "./redux/saloon/SaloonSlice";
import AddSaloonServices from "./pages/add-saloon/AddSaloonServices";
import ServiceList from "./pages/add-saloon/ServiceList";
import SaloonBookedOrder from "./pages/saloonOrders/SaloonBookedOrders";
import SaloonAcceptedOrders from "./pages/saloonOrders/SaloonAcceptedOrders";
import SaloonIntransitOrder from "./pages/saloonOrders/SaloonIntransitorders";
import SaloonCompletedOrder from "./pages/saloonOrders/SaloonCompletedOrders";
import SaloonCancelledOrder from "./pages/saloonOrders/SaloonCancelledOrder";
import AddSaloonCategory from "./pages/add-saloon/AddSaloonCategory";
import { getSaloonCategory } from "./redux/saloonCategory/SaloonCategorySlice";
import ListSaloonCategory from "./pages/add-saloon/ListSaloonCategory";
import BulkUploadCategory from "./pages/add-saloon/AddSaloonBulkCat";
import AddBulkServices from "./pages/add-saloon/AddBulkServices";
import PendingSaloon from "./pages/add-saloon/PendingSaloon";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const initialSetup = async () => {
      try {
        // getSuperCategory
        dispatch(getSuperCategory());

        // getCategory
        dispatch(getCategory());

        // getBrand
        dispatch(getBrand());

        // getSubCategory
        dispatch(getSubCategory());

        // getservices
        dispatch(getservices());

        // getservices
        dispatch(getCar());

        // getsaloons
        dispatch(getSaloonList());

        // getsalooncategory
        dispatch(getSaloonCategory());
      } catch (error) {}
    };
    initialSetup();
  }, []);

  return (
    <div className="wrapper">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route path="/admin/order-invoice" element={<OrderInvoice />} />
          <Route path="/admin" exact element={<MainLayout />}>
            <Route index element={<Dashboard />} />

            {/* supercategory */}
            <Route
              path="/admin/addsupercategory"
              element={<AddSuperCategory />}
            />

            <Route
              path="/admin/listsupercategory"
              element={<ListSuperCategory />}
            />

            <Route
              path="/admin/updatesupercategory/:slugurl"
              element={<UpdateSuperCategory />}
            />

            {/* category */}
            <Route path="/admin/addcategory" element={<AddCategory />} />
            <Route path="/admin/listcategory" element={<ListCategory />} />
            <Route
              path="/admin/updatecategory/:slugurl"
              element={<UpdateCategory />}
            />

            {/* brand */}
            <Route path="/admin/addbrand" element={<AddBrand />} />
            <Route path="/admin/listbrand" element={<ListBrand />} />
            <Route
              path="/admin/updatebrand/:slugurl"
              element={<UpdateBrand />}
            />

            {/* subcategory */}
            <Route path="/admin/addsubcategory" element={<AddSubCategory />} />
            <Route
              path="/admin/listsubcategory"
              element={<ListSubCategory />}
            />
            <Route
              path="/admin/updatesubcategory/:slugurl"
              element={<UpdateSubCategory />}
            />

            {/* grocery */}
            <Route path="/admin/addgrocery" element={<AddGrocery />} />
            <Route path="/admin/listproduct" element={<ListGrocery />} />
            <Route
              path="/admin/updategrocery/:slugurl"
              element={<UpdateGrocery />}
            />

            {/* order details */}
            <Route path="/admin/bookedorder" element={<BookedOrder />} />
            <Route
              path="/admin/orderdetails/:orderid"
              element={<OrderDetails />}
            />
            <Route
              path="/admin/assign-delivery/:orderid"
              element={<AssignDeliveryPersion />}
            />
            <Route path="/admin/cancelledorder" element={<CancelledOrder />} />
            <Route path="/admin/deliveredorder" element={<DeliveredOrder />} />
            <Route path="/admin/dispatchorder" element={<DispatchOrder />} />
            <Route
              path="/admin/outfordeliveryorder"
              element={<OutForDeliveryOrder />}
            />
            <Route
              path="/admin/processingorder"
              element={<ProcessingOrder />}
            />
            {/* Saloon Orders */}
            <Route
              path="/admin/saloon/bookingreceived"
              element={<SaloonBookedOrder />}
            />
            <Route
              path="/admin/saloon/bookingaccepted"
              element={<SaloonAcceptedOrders />}
            />
            <Route
              path="/admin/saloon/bookingintransit"
              element={<SaloonIntransitOrder />}
            />
            <Route
              path="/admin/saloon/bookingcompleted"
              element={<SaloonCompletedOrder />}
            />
            <Route
              path="/admin/saloon/bookingcancelled"
              element={<SaloonCancelledOrder />}
            />

            <Route path="/admin/add-services" element={<AddServices />} />

            <Route path="/admin/add-car" element={<AddCar />} />
            <Route path="/admin/add-car-model" element={<CarModelAdd />} />

            {/* saloon */}
            <Route path="/admin/addsaloon" element={<AddSaloon />} />
            <Route path="/admin/listsaloon" element={<ListSaloon />} />
            <Route path="/admin/pendingsaloon" element={<PendingSaloon />} />
            <Route
              path="/admin/updatesaloon/:slugurl"
              element={<UpdateSaloon />}
            />
            <Route
              path="/admin/saloon/addservices"
              element={<AddSaloonServices />}
            />
            <Route
              path="/admin/saloon/addbulkservices"
              element={<AddBulkServices />}
            />
            <Route
              path="/admin/saloon/addcategory"
              element={<AddSaloonCategory />}
            />
            <Route
              path="/admin/saloon/bulkuploadcategory"
              element={<BulkUploadCategory />}
            />
            <Route
              path="/admin/saloon/listcategory"
              element={<ListSaloonCategory />}
            />
            <Route path="/admin/servicelist/:_id" element={<ServiceList />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
