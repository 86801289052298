import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Modal, ModalHeader } from "reactstrap";
import Table from "react-bootstrap/Table";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { brandDelete } from "../../redux/brand/brandSlice";

const PendingSaloon = () => {
  const dispatch = useDispatch();

  const { saloonList } = useSelector((store) => store.saloon);

  //   console.log(saloonList, "saloonList");

  const [selectBrand, setSelectBrand] = useState("");
  const [PendingSaloon, setPendingSaloon] = useState([]);
  const [cancelmodel, setCancelModel] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [selectedSaloon, setSelectedSaloon] = useState("");

  const cancelClick = (e, subcategory) => {
    setCancelModel(true);
    setSelectBrand(subcategory);
  };

  const deleteClick = async (e, id) => {
    const data = await dispatch(brandDelete(selectBrand._id));
    if (data.payload.success) {
      // Remove the item from the PendingSaloon state
      setPendingSaloon((prev) => prev.filter(saloon => saloon._id !== selectBrand._id));

      // Close the modal
      setCancelModel(false);
    } else {
      console.log('Failed to delete');
    }
  
  };
  const statusChange = async (e, data) => {
    e.preventDefault();
    console.log(data);
    const value = e.target.checked;

    if (data) {
      const formData = {
        brandid: data._id,
        status: value,
      };
      console.log(formData);
      //   const updateCat = await dispatch(brandUpdate(formData));
    }
  };
  useEffect(async () => {
    const url = `${Baseurl}/api/v1/saloon/pendingApprovals`;
    const response = await axios.get(url);
    console.log(response.data, "response");
    // console.log(response.data.name, "name response");
    if (response.data.success) {
      setPendingSaloon(response.data.saloon);
    }
  }, []);

  console.log(selectedSaloon, "selectedSaloon");
  console.log(cancelmodel, "cancelmodel");

  const approveSaloon = async () => {
    const formData = {
      approved: true,
    };
    const config = {
      Headers: { "Content-Type": "application/json" },
    };
    const url = `${Baseurl}/api/v1/saloon/update/${selectedSaloon._id}`;
    const resp = await axios.put(url, formData, config);
    setCancelModel(false);
    console.log(resp.data, "resp.data");
    console.log(cancelmodel, "Approve cancelmodel");
  };
  console.log(PendingSaloon, "PendingSaloon12345");

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div
            style={{ height: 250, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <Table hover class="table table-striped">
              <thead>
                <tr style={{ height: "10px" }}>
                  <th>#</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Saloon Type</th>
                  <th>Desktop Image</th>
                  <th>Address</th>
                  <th>Area</th>
                  {/* <th>Status</th> */}
                  <th>Action</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {PendingSaloon &&
                  PendingSaloon.map((indSaloon, index) => (
                    <tr style={{ height: "10px", width: "240px" }}>
                      <th scope="row" style={{ height: "10px" }}>
                        {index + 1}
                      </th>
                      <td>{indSaloon.name}</td>
                      <td>{indSaloon.phone}</td>
                      <td>{indSaloon.saloonFor}</td>
                      <td>
                        <img
                          src={indSaloon.desktopImage}
                          alt="salon-image"
                          height="50"
                          width="50"
                        />
                      </td>
                      <td>{indSaloon.address}</td>
                      <td>{indSaloon.area}</td>

                      <td style={{ height: "10px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => cancelClick(e, indSaloon)}
                        >
                          Approve
                        </Button>
                        {/* </Link> */}
                      </td>
                      <td style={{ height: "10px" }}>
                        <Button
                          variant="contained"
                          onClick={(e) => cancelClick(e, indSaloon)}
                          color="error"
                        >
                          Reject
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          {/* 
          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Reject</span>{" "}
                      {selectBrand !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectBrand.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Brand
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal> */}

          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "green" }}>Approve</span>{" "}
                      {selectedSaloon !== "" ? (
                        <>
                          <span
                            style={{
                              color: "green",
                            }}
                          >
                            {selectedSaloon.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Saloon
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                         OK
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </Fragment>
    </>
  );
};

export default PendingSaloon;
