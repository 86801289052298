import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    servicestotal: localStorage.getItem("servicestotal")
      ? JSON.parse(localStorage.getItem("servicestotal")).sort()
      : [],
    isLoading: true,
    servicesLoading: true,
    delservicesLoading: true,
  };

  export const getservices = createAsyncThunk("services/getservices", async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/services/all`;
      const resp = await axios(url);
      return resp.data.services;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  });

  export const servicesPost = createAsyncThunk(
    "services/servicesPost",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/services/new`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("services Not create");
      }
    }
);
  

  export const validateSevicesSlugUrl = createAsyncThunk(
    "services/validateSevicesSlugUrl",
    async (slugurl , thunkAPI) => {
      let resp = {
        success: false,
        message: "new email",
      }
      try {
        const url = `${Baseurl}/api/v1/services/slugurl/${slugurl}`;
        const resp = await axios.get(url);
        return resp.data;
      } catch (error) {
        return error;
      }
    }
  );

  export const servicesUpdate = createAsyncThunk(
    "services/servicesUpdate",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/services/${formData.servicesid}`;
        const resp = await axios.put(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("services Not create");
      }
    }
  );

  export const servicesDelete = createAsyncThunk(
    "services/servicesDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/services/${id}`;
        const resp = await axios.delete(url, id, config);
        const myreturn = {
          success: resp.data.success,
          id: id,
        };
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("services Not create");
      }
    }
  );

const ServicesSlice = createSlice({
    name: "services",
    initialState,
    reducers: {
    },
    extraReducers: {
      [getservices.pending]: (state) => {
        state.isLoading = true;
      },
      [getservices.fulfilled]: (state, action) => {
        state.servicestotal = action.payload.sort();
        localStorage.setItem("servicestotal", JSON.stringify(state.servicestotal));
        state.isLoading = false;
        state.servicesLoading = false;
      },
      [getservices.rejected]: (state, action) => {
        state.isLoading = true;
      },
      [servicesPost.pending]: (state) => {
        state.servicesLoading = true;
      },
  
      [servicesPost.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.servicestotal = [...state.servicestotal, action.payload.service].sort();
          localStorage.setItem("servicestotal", JSON.stringify(state.servicestotal));
        }
        state.servicesLoading = false;
      },
  
      [servicesPost.rejected]: (state, action) => {
        state.servicesLoading = true;
      },

      [servicesUpdate.pending]: (state) => {
        state.servicesLoading = true;
      },
  
      [servicesUpdate.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.servicestotal = state.servicestotal.filter(
            (services) => services._id !== action.payload.service._id
          );
          state.servicestotal = [...state.servicestotal, action.payload.service];
          localStorage.setItem("servicestotal", JSON.stringify(state.servicestotal));
        }
        state.servicesLoading = false;
      },
  
      [servicesUpdate.rejected]: (state, action) => {
        state.servicesLoading = true;
      },

      [servicesDelete.pending]: (state) => {
        state.delservicesLoading = true;
      },
  
      [servicesDelete.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.servicestotal = state.servicestotal.filter(
            (services) => services._id !== action.payload.id
          ).sort();
          localStorage.setItem("servicestotal", JSON.stringify(state.servicestotal));
        }
        state.delservicesLoading = false;
      },
  
      [servicesDelete.rejected]: (state, action) => {
        state.delservicesLoading = true;
      },
    },
  });
  export const {
  } = ServicesSlice.actions;
  export default ServicesSlice.reducer;
  