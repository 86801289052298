

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    Cartotal: localStorage.getItem("Cartotal")
        ? JSON.parse(localStorage.getItem("Cartotal"))
        : [],
    mobileimage: "",
    isCarmobileImageLoading: true,
    isLoading: true,
    carLoading: true,
    deleteCarLoading: true,
    checkSlugurl: true,
};

export const getCar = createAsyncThunk(
    "cars/getCar",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/cars/all`;
            const resp = await axios(url);
            return resp.data.cars;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);

export const carPost = createAsyncThunk(
    "cars/carPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/cars/new`;
            const resp = await axios.post(url, formData, config);
            console.log(resp.data);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("cars Not create");
        }
    }
);

export const carMobileImage = createAsyncThunk(
    "cars/carMobileImage",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/cars/carimage`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("cars Icon Not create");
        }
    }
);



export const validateSlugUrl = createAsyncThunk(
    "cars/validateSlugUrl",
    async (slugurl, thunkAPI) => {
        let resp = {
            success: false,
            message: "new email",
        };
        try {
            const url = `${Baseurl}/api/v1/cars/slugurl/${slugurl}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);

export const carUpdate = createAsyncThunk(
    "cars/carUpdate",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/cars/${formData.catid}`;
            const resp = await axios.put(url, formData, config);

            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("cars Not create");
        }
    }
);
export const carDelete = createAsyncThunk(
    "cars/carDelete",
    async (id, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/cars/${id}`;
            const resp = await axios.delete(url, id, config);
            console.log(resp.data);
            const myreturn = {
                success: resp.data.success,
                id: id,
            };
            return myreturn;
        } catch (error) {
            return thunkAPI.rejectWithValue("cars Not create");
        }
    }
);

const CarSlice = createSlice({
    name: "car",
    initialState,
    reducers: {
        updateCatMobileImage(state, action) {
            state.mobileimage = action.payload;
            state.isCarmobileImageLoading = false;
        },
        deleteCategory(state, action) {
            state.Cartotal = state.Cartotal
                .filter((cars) => cars._id !== action.payload);
        },
    },
    extraReducers: {
        [getCar.pending]: (state) => {
            state.isLoading = true;
        },
        [getCar.fulfilled]: (state, action) => {
            state.Cartotal = action.payload;
            localStorage.setItem("Cartotal", JSON.stringify(state.Cartotal));
            state.isLoading = false;
            state.carLoading = false;
        },
        [getCar.rejected]: (state, action) => {
            state.isLoading = true;
        },

        [carPost.pending]: (state) => {
            state.carLoading = true;
        },

        [carPost.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.Cartotal = [
                    ...state.Cartotal,
                    action.payload.car,
                ].sort();
                localStorage.setItem(
                    "Cartotal",
                    JSON.stringify(state.Cartotal)
                );
                state.mobileimage = "";
                state.isCarmobileImageLoading = true;
            }
            state.carLoading = false;
            state.checkSlugurl = false;
        },

        [carPost.rejected]: (state, action) => {
            state.carLoading = true;
        },

        [carMobileImage.pending]: (state) => {
            state.isCarmobileImageLoading = true;
        },
        [carMobileImage.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.mobileimage = action.payload.carimages;
            }
            state.isCarmobileImageLoading = false;
        },

        [carMobileImage.rejected]: (state, action) => {
            state.isCarmobileImageLoading = true;
        },



        [carUpdate.pending]: (state) => {
            state.carLoading = true;
        },

        [carUpdate.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.Cartotal = state.Cartotal.filter(
                    (car) => car._id !== action.payload.car._id
                );
                state.Cartotal = [
                    ...state.Cartotal,
                    action.payload.car,
                ];
                state.mobileimage = "";
                state.isCarmobileImageLoading = true;
                localStorage.setItem(
                    "Cartotal",
                    JSON.stringify(state.Cartotal)
                );
            }

            state.carLoading = false;
        },

        [carUpdate.rejected]: (state, action) => {
            state.carLoading = true;
        },

        [carDelete.pending]: (state) => {
            state.deleteCarLoading = true;
        },

        [carDelete.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.Cartotal = state.Cartotal
                    .filter((cars) => cars._id !== action.payload.id);
                localStorage.setItem(
                    "Cartotal",
                    JSON.stringify(state.Cartotal)
                );
            }
            state.deleteCarLoading = false;
        },

        [carDelete.rejected]: (state, action) => {
            state.deleteCarLoading = true;
        },
    },
});
export const {
    updateCatMobileImage,
    deleteCategory,
} = CarSlice.actions;
export default CarSlice.reducer;
