import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  carMobileImage,
  carPost,
  validateSlugUrl,
} from "../../../redux/car/CarSlice";
import { read, utils } from "xlsx";

const AddCar = () => {
  const { mobileimage, isCarmobileImageLoading } = useSelector(
    (store) => store.car
  );

  const [categroy, setCategroy] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [result, setResult] = useState([]);
  const [selectServices, setSelectServices] = useState("");

  const [errormassageEmail, setErrormassageEmail] = useState([]);
  const [suceessmassage, setSuceessmassage] = useState("");
  const [color, setColor] = useState("tomato");
  const [data, setData] = useState("");

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setCategroy(value);
    // setErrorcolor("green");
    setCheckSlugUrl("");
    // setError("category available");
    const catSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(catSlug);

    if (catSlug !== "") {
      let responce = await dispatch(validateSlugUrl(catSlug));
      if (responce.payload.success) {
        setError("category alredy exist");
        setErrorcolor("red");
      } else if (catSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (catSlug.length >= 3) {
        setErrorcolor("");
        setError("");
        setCheckSlugUrl(catSlug);
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (checkslugUrl !== "") {
      const formData = {
        name:
          categroy.charAt(0).toUpperCase() + categroy.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        Image: mobileimage,
      };
      console.log(formData);
      const data = await dispatch(carPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Car Added");
          setCategroy("");
          setSlugUrl("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setSupercat("");
          setSupercatid("");
        } else {
          alert(data.message);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  const selectSuperCat = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercatid(value);
    setSupercat(text);
  };

  const dispatch = useDispatch();

  const categoryImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(carMobileImage({ carimage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas);
    setData(datas);
  };

  const handlesubmitt = async (e) => {
    e.preventDefault();

    for (let i in data) {
      let count = 0;

      try {
        let myrow = data[i];

        const formData = {
          business: Number(myrow.business),
        };

        // if (data.payload.success) {
        //   let curAgent = data.payload.agent;
        //   setSuceessmassage(
        //     `${i}) ${data.payload.agent.name} agent data submitted successfully`
        //   );
        // }
      } catch (error) {}
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer d-flex justify-content-between">
                  <h5>Add Car</h5>
                  <button className="btn btn-primary">
                    Model Bulk Uploade
                  </button>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Car Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Car"
                        required
                        value={categroy}
                        onChange={(e) => verifyslugurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label> Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={categoryImageChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 191px * 218px
                      </p>
                      <div>
                        {isCarmobileImageLoading ? (
                          <div></div>
                        ) : (
                          <img src={mobileimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {checkslugUrl !== "" && !isCarmobileImageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Car
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Car
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddCar;
