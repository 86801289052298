import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  servicesPost,
  validateSevicesSlugUrl,
} from "../../../redux/services/ServicesSlice";

const AddServices = () => {
  const [brand, setBrand] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const dispatch = useDispatch();

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setBrand(value);
    setCheckSlugUrl("");
    const brandSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(brandSlug);

    if (brandSlug !== "") {
      let responce = await dispatch(validateSevicesSlugUrl(brandSlug));
      if (responce.payload.success) {
        setError("Services alredy exist");
        setErrorcolor("red");
      } else if (brandSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (brandSlug.length >= 3) {
        setErrorcolor(" ");
        setError("");
        setCheckSlugUrl(brandSlug);
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (checkslugUrl !== "") {
      const formData = {
        name: brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        show: true,
      };
      const data = await dispatch(servicesPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Services Added");
          setBrand("");
          setSlugUrl("");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Services</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Services</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 "></Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={brand}
                        required
                        onChange={(e) => verifyslugurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {checkslugUrl !== "" ? (
                    <button className="btn btn-primary" type="submit">
                      Add Services
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Services
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddServices;
