import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./redux/Athentication/AuthSlice";
import superCategoryReducer from "./redux/superCategory/superCategorySlice"
import categoryAllReducer from "./redux/category/categorySlice";
import brandReducer from './redux/brand/brandSlice';
import subCategoriesReducer from "./redux/subCategory/subCategorySlice";
import groceriesReducer from "./redux/grocery/grocerySlice";
import ordersReducer from "./redux/order/orderSlice";
import servicesReducer from "./redux/services/ServicesSlice";
import carReducer from "./redux/car/CarSlice";
import carModelReducer from "./redux/carModel/CarModel";
import productsReducer from "./redux/product/productSlice";
import saloonReducer from "./redux/saloon/SaloonSlice";
import saloonCategoryReducer from "./redux/saloonCategory/SaloonCategorySlice";
import saloonServiceReducer from "./redux/salonService/SaloonServiceSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    superCategory: superCategoryReducer,
    categoryAll: categoryAllReducer,
    brand: brandReducer,
    subCategories: subCategoriesReducer,
    groceries: groceriesReducer,
    orders: ordersReducer,
    services: servicesReducer,
    car: carReducer,
    carModel: carModelReducer,
    products:productsReducer,
    saloon:saloonReducer,
    saloonCategoryAll:saloonCategoryReducer,
    saloonService:saloonServiceReducer,
  },
});