  import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
  import axios from "axios";
  import { Baseurl } from "../../config/BaseUrl";

  const initialState = {
    ordertotal: [],
    bookedorder: [],
    processingorder: [],
    dispatchorder: [],
    outfordeliveryorder: [],
    deliveredorder: [],
    cancelledorder: [],
    orderItemtotal: [],
    singleOrder: localStorage.getItem("singleOrder")
      ? JSON.parse(localStorage.getItem("singleOrder"))
      : "",
    isLoading: true,
    isOrderLoading: true,
    isOrderForLoading: true,
  };

  export const getOrder = createAsyncThunk("Order/getOrder", async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/all`;
      const resp = await axios(url);
      return resp.data.orders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  });
  export const postOrder = createAsyncThunk(
    "Order/postOrder",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/order/new`;
        const resp = await axios.post(url, formData, config);
        console.log(resp.data);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("order Not create");
      }
    }
  );

  export const updateOrder = createAsyncThunk(
    "Order/updateOrder",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/order/${formData.orderid}`;

        const resp = await axios.put(url, formData, config);

        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("order Not Updated");
      }
    }
  );
  export const updateOrdersingleItem = createAsyncThunk(
    "Order/updateOrdersingleItem",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/order/updatesingleorderitem`;

        const resp = await axios.put(url, formData, config);

        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("order Not Updated");
      }
    }
  );

  const OrderSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
      setSingleOrder(state, action) {
        state.singleOrder = action.payload;
        localStorage.setItem("singleOrder", JSON.stringify(state.singleOrder));
      },
    },
    extraReducers: {
      [getOrder.pending]: (state) => {
        state.isLoading = true;
      },
      [getOrder.fulfilled]: (state, action) => {
        state.ordertotal = action.payload;
        state.bookedorder = state.ordertotal.filter(
          (order) => order.Status === 1
        );
        state.processingorder = state.ordertotal.filter(
          (order) => order.Status === 2
        );
        state.dispatchorder = state.ordertotal.filter(
          (order) => order.Status === 3
        );
        state.outfordeliveryorder = state.ordertotal.filter(
          (order) => order.Status === 4
        );
        state.deliveredorder = state.ordertotal.filter(
          (order) => order.Status === 5
        );
        state.cancelledorder = state.ordertotal.filter(
          (order) => order.Status === 0
        );

        let itemArray = [];
        for (let index = 0; index < state.processingorder.length; index++) {
          const element = state.processingorder[index].OrderProducts;
          itemArray.push(element);
        }
        itemArray = itemArray.flat(1);

        state.orderItemtotal = itemArray;

        state.isLoading = false;
        state.isOrderLoading = false;
      },
      [getOrder.rejected]: (state, action) => {
        state.isLoading = true;
      },

      [postOrder.pending]: (state) => {
        state.isOrderLoading = true;
      },
      [postOrder.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.ordertotal = [...state.ordertotal, action.payload.order];
          state.bookedorder = state.ordertotal.filter(
            (order) => order.Status === 1
          );
          state.processingorder = state.ordertotal.filter(
            (order) => order.Status === 2
          );
          state.dispatchorder = state.ordertotal.filter(
            (order) => order.Status === 3
          );
          state.outfordeliveryorder = state.ordertotal.filter(
            (order) => order.Status === 4
          );
          state.deliveredorder = state.ordertotal.filter(
            (order) => order.Status === 5
          );
          state.cancelledorder = state.ordertotal.filter(
            (order) => order.Status === 0
          );

          let itemArray = [];
          for (let index = 0; index < state.processingorder.length; index++) {
            const element = state.processingorder[index].OrderProducts;
            itemArray.push(element);
          }
          itemArray = itemArray.flat(1);

          state.orderItemtotal = itemArray;
        }
        state.isOrderLoading = false;
      },
      [postOrder.rejected]: (state, action) => {
        state.isOrderLoading = true;
      },

      [updateOrder.pending]: (state) => {
        state.isOrderForLoading = true;
      },

      [updateOrder.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.ordertotal = state.ordertotal.filter(
            (order) => order._id !== action.payload.order._id
          );
          state.ordertotal = [...state.ordertotal, action.payload.order];
          state.bookedorder = state.ordertotal.filter(
            (order) => order.Status === 1
          );
          state.processingorder = state.ordertotal.filter(
            (order) => order.Status === 2
          );
          state.dispatchorder = state.ordertotal.filter(
            (order) => order.Status === 3
          );
          state.outfordeliveryorder = state.ordertotal.filter(
            (order) => order.Status === 4
          );
          state.deliveredorder = state.ordertotal.filter(
            (order) => order.Status === 5
          );
          state.cancelledorder = state.ordertotal.filter(
            (order) => order.Status === 0
          );
          let itemArray = [];
          for (let index = 0; index < state.processingorder.length; index++) {
            const element = state.processingorder[index].OrderProducts;
            itemArray.push(element);
          }
          itemArray = itemArray.flat(1);

          state.orderItemtotal = itemArray;
        }
        state.isOrderForLoading = false;
      },

      [updateOrder.rejected]: (state, action) => {
        state.isOrderForLoading = true;
      },
      [updateOrdersingleItem.pending]: (state) => {
        state.isOrderLoading = true;
      },

      [updateOrdersingleItem.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.ordertotal = state.ordertotal.filter(
            (order) => order._id !== action.payload.order._id
          );
          state.ordertotal = [...state.ordertotal, action.payload.order];
          state.bookedorder = state.ordertotal.filter(
            (order) => order.Status === 1
          );
          state.processingorder = state.ordertotal.filter(
            (order) => order.Status === 2
          );
          state.dispatchorder = state.ordertotal.filter(
            (order) => order.Status === 3
          );
          state.outfordeliveryorder = state.ordertotal.filter(
            (order) => order.Status === 4
          );
          state.deliveredorder = state.ordertotal.filter(
            (order) => order.Status === 5
          );
          state.cancelledorder = state.ordertotal.filter(
            (order) => order.Status === 0
          );
          let itemArray = [];
          for (let index = 0; index < state.processingorder.length; index++) {
            const element = state.processingorder[index].OrderProducts;
            itemArray.push(element);
          }
          itemArray = itemArray.flat(1);

          state.orderItemtotal = itemArray;
        }
        state.isOrderLoading = false;
      },

      [updateOrdersingleItem.rejected]: (state, action) => {
        state.isOrderLoading = true;
      },
    },
  });
  export const { setSingleOrder } = OrderSlice.actions;
  export default OrderSlice.reducer;
